
import {computed, defineComponent, onMounted, ref} from "vue";
import KtDatatable from "../../components/kt-datatable/KTDatatable.vue";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadFilterObjects, LoadPanel, TeamSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Entities from "@/components/base/common/Entities.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import ReminderName from "@/views/reminder/ReminderName.vue";

export default defineComponent({
  name: "Search",
  components: {ReminderName, UserListSelect, BaseSelect, Entities, DateTimeFormat, Avatar, KtDatatable},
  setup() {
    const objectFilter = ref({assigneeId: '', teamId: '', complete: '', overdue: false})
    const page = computed(() => store.state.ReminderModule.allPage);
    const header = [
      {
        name: 'created',
        key: 'created',
        sortable: false
      },
      {
        name: 'Entity',
        key: 'related',
        sortable: false
      },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'DueDate',
        key: 'dueDate',
        sortable: false
      },
    ]
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("All Reminders",
        [
          {link: false, router: '', text: 'Reminders'},
          {link: false, router: '', text: 'All Reminders'}
        ]
      )
    })
    return {
      objectFilter,
      header,
      ...LoadFilterObjects(Actions.LOAD_ALL_REMINDER, objectFilter.value, ['assignee', 'team','caze','claim']),
      page,
      ...LoadPanel(),
      ...TeamSelect()
    }
  },
  methods: {
    discardEvent() {
      this.objectFilter.assigneeId = ''
      this.objectFilter.teamId = ''
      this.objectFilter.complete = ''
      this.objectFilter.overdue = false
      this.updateFilterObject(this.objectFilter);
    },
    searchEvent() {
      this.updateFilterObject(this.objectFilter)
    },
  }
})
